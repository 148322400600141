import React from 'react';
import { Helmet } from 'react-helmet';
import { getHeaderFontFamily } from './node/utils';
const headerFont = getHeaderFontFamily()[0];
const ImportNeoSans = () => (
  <Helmet>
    <link rel="stylesheet" href="https://use.typekit.net/siv8cjt.css" />
  </Helmet>
);
export const wrapRootElement = ({ element }) => {
  return (
    <>
      {headerFont === 'neo-sans' ? <ImportNeoSans /> : null}
      {element}
    </>
  );
};
