module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://igbau-email.wings.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#24A42E","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/node_modules/@wingscms/hummingbird/lib/typography.js"},
    },{
      plugin: require('../node_modules/@wingscms/hummingbird/gatsby-browser.js'),
      options: {"plugins":[],"wings":{"project":"VqR7NEN20Gf3WBvQVE5Q","appKey":"app_key_adaa6ecbd10356c94c72ae1ee0cc5e55974ead1f98940819"},"blockRobots":false,"campaigns":{"formProps":{"disabledFields":["privacyConsent","terms"]}},"design":{"headerFontFamily":"Fira Sans, sans-serif","siteTitle":"Publieke Omroep: Juist Nu","primaryColor":"#E3333A","secondaryColor":"#000","navigationBackgroundColor":"#E3333A","backgroundColorHighlight":"#fff","logoImageUrl":"https://files.wings.dev/WrJGDrByYwS4gnjzGXgA/1674459915282/igb-logo-wyxyvzodecaeglc0vtwuiy1xuulenqvhrwcbkqtzdksfqlrplenlln8wq28xk7vjxjjazfqcagry0aunmqcs1zlikvcp4afrmv3g.png","faviconImageUrl":"https://files.wings.dev/WrJGDrByYwS4gnjzGXgA/1674459949229/favicon196.png","intentColor":"#0f9960","warningColor":"#d9822b","dangerColor":"#db3737","appBackgroundColor":"#ecf0f1","appBackgroundColorDark":"#000000","textColor":"#182026","textColorDark":"#fdfdfd","footerBackgroundColor":"#B2B2B2","footerTextColor":"#fff","navigationIconColor":"#E3333A","landingSectionTitleBackgroundColor":"#E3333A","elementBackgroundColor":"#fff","backgroundHighlightColor":"#fff","colorChapterIntro":"#fff","linkColor":"#E3333A","backgroundColor":"#F7F7F7","blockquoteBackgroundColor":"#fff","landingSectionTitleColor":"#fff","counterBackgroundColor":"#fff","formBackgroundColor":"#E3333A"},"typography":{"headerFontFamily":["Fira Sans","sans-serif"],"bodyFontFamily":["Fira Sans","serif"],"googleFonts":[{"name":"Fira Sans","styles":["300","300i","700","700i"]}]},"footer":{"title":"","logoUrl":"","logoLink":"","columns":[{"title":"","rows":[{"type":"link","url":"https://www.fnv.nl/privacy","content":"Privacy"}]}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
